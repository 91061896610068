<template>
<div class="">
  <div class="vx-card mt-base">
    <div class="vx-row item-details no-gutter">
      <div
        class="vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center product-image cursor-pointer"
      >
        <img class="p-5" :src="event.imageUrl" alt="" />
      </div>
      <div class="vx-col sm:w-1/2 flex items-center">
        <div class="p-5 pt-6 w-full">
          <div class="flex items-center justify-between">
          <h5 class="font-semibold mb-1 text-primary">{{ event.title }}</h5>
          <div>
           
            <template v-if="isEdit">
              <feather-icon
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="$emit('toggleEditPopup')"
                  icon="EditIcon"
                  :svgClasses="iconSize"
                />
              <feather-icon
                class="cursor-pointer hover:bg-grey p-2 rounded"
                @click="$emit('toggleDeletePopup')"
                icon="Trash2Icon"
                :svgClasses="iconSize"
              />
            </template>
          </div>
          </div>
          <div class="mt-1">
          <p>
            <span class="font-semibold">Presenter:</span
            ><span class="text-muted ml-2">{{ event.presenter }}</span>
          </p>
          </div>
          <div class="mt-1">
          <p>
            <span class="font-semibold">Category:</span
            ><span class="text-muted ml-2">{{ event.category }}</span>
          </p>
          </div>
          <div class="mt-1">
          <p>
             <span class="font-semibold">Status:</span
            ><span class="text-muted ml-2">{{ event.status ? 'Open' : 'Closed' }}</span>
          </p>
          </div>
          <div class="flex mt-1">
            <p class="mr-4">
              <span class="font-semibold">Start Time:</span
              ><span class="text-muted ml-2">{{ event.startDate }}</span>
            </p>
            <p>
              <span class="font-semibold">End Time:</span
              ><span class="text-muted ml-2">{{ event.endDate }}</span>
            </p>
          </div>
          <div class="mt-1">
            <p class="mr-4">
              <span class="font-semibold">Method:</span
              ><span class="text-muted ml-2">{{ event.method }}</span>
            </p>
            <!-- <p v-if="event.zoomLink && event.method === 'Online'">
              <span class="font-semibold">Zoom link:</span
              ><a class="ml-2" :href="event.zoomLink" target="__blank">Go to Zoom</a>
            </p> -->
          </div>
          <div class="mt-3 text-truncate">
            <span class="font-semibold">Description:</span>
            <p v-html="event.description"></p>
          </div>
          
           <div class="mt-2">
            <p>
              <span class="font-semibold">Listed by:</span
              ><span class="text-muted ml-2">{{ event.offererRef.displayName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="sm:w-1/4 w-full flex flex-col border-left p-4">
        <feather-icon
          class="cursor-pointer hover:bg-grey p-2 mt-2 rounded ml-auto"
          icon="BookmarkIcon"
          @click="toggleBookmark(event.isBookmark)"
          :svgClasses="event.isBookmark ? iconSize + ' text-warning fill-current stroke-current' : iconSize"
        />
        <div class="p-4 flex flex-col">
          <div class="my-6">
            <h5 class="">
              <span v-if="event.discountPrice" class="font-bold text-success"
                >${{ event.discountPrice }}</span
              >
              <span v-else>This event is free</span>
              <span
                v-if="event.price"
                class="text-primary ml-2"
                style="text-decoration: line-through"
                >${{ event.price }}</span
              >
            </h5>
            <p>
              <span class="font-semibold">City:</span
              ><span class="text-muted ml-2">{{ event.city }}</span>
            </p>
            <div class="mt-1">
            <p v-if="event.address">
              <span class="font-semibold">Address</span
              ><span class="text-muted ml-2">{{ event.address }}</span>
            </p>
            </div>
             <p class="text-light-blue">
               <vs-button @click="popupActive = true" class="mt-4"
                >View event</vs-button>
              <!-- <vs-button @click="goToEventLink(event.url)" class="mt-4"
                >Go to event</vs-button> -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- View Events popup -->
      <vs-popup
      ref=""
      title="Events details"
      :active.sync="popupActive"
    >
    <div class="view-course px-4 py-2">
    <div class="">
      <h4 class="font-semibold mb-1 text-primary">{{ event.title }}</h4>
    </div>
    <div class="">
      <h5 class="mt-2">
              <span v-if="event.discountPrice" class="font-bold text-success"
                >${{ event.discountPrice }}</span
              >
              <span v-else>This event is free</span>
              <span
                v-if="event.price"
                class="text-primary ml-2"
                style="text-decoration: line-through"
                >${{ event.price }}</span
              >
            </h5>
    </div>
    <div class="mt-1">
          <p>
            <span class="font-semibold">Presenter:</span
            ><span class="text-muted ml-2">{{ event.presenter }}</span>
          </p>
          </div>
          <div class="mt-1">
          <p>
            <span class="font-semibold">Category:</span
            ><span class="text-muted ml-2">{{ event.category }}</span>
          </p>
          </div>
          <div class="mt-1">
          <p>
             <span class="font-semibold">Status:</span
            ><span class="text-muted ml-2">{{ event.status ? 'Open' : 'Closed' }}</span>
          </p>
          </div>
          <div class="flex mt-1">
            <p class="mr-4">
              <span class="font-semibold">Start Time:</span
              ><span class="text-muted ml-2">{{ event.startDate }}</span>
            </p>
            <p>
              <span class="font-semibold">End Time:</span
              ><span class="text-muted ml-2">{{ event.endDate }}</span>
            </p>
          </div>
          <div class="mt-1">
            <p class="mr-4">
              <span class="font-semibold">Method:</span
              ><span class="text-muted ml-2">{{ event.method }}</span>
            </p>
            <!-- <p v-if="event.zoomLink && event.method === 'Online'">
              <span class="font-semibold">Zoom link:</span
              ><a class="ml-2" :href="event.zoomLink" target="__blank">Go to Zoom</a>
            </p> -->
          </div>
          <div class="mt-1">
            <p>
              <span class="font-semibold">City:</span
              ><span class="text-muted ml-2">{{ event.city }}</span>
            </p>
          </div>
          <div class="mt-1">
            <p v-if="event.address">
              <span class="font-semibold">Address</span
              ><span class="text-muted ml-2">{{ event.address }}</span>
            </p>
            </div>
            <div class="mt-3">
            <span class="font-semibold lead">Description:</span>
            <p v-html="event.description"></p>
          </div>
      <div class="view-course py-2">
              <vs-button @click="goToEventLink(event.url)" class="mt-4"
                >Go to event</vs-button>
      </div>
    </div>
    </vs-popup>
</div>
</template>

<script>
export default {
  name: 'EventListItem',
  props: ['event'],
  data () {
    return {
      popupActive: false,
      iconSize: 'w-5 h-5'
    }
  },
  computed: {
    isEdit () {
      return this.event.offerer === this.$store.state.auth.userData.name
    }
  },
  methods: {
    toggleBookmark(isBookmark) {
      if (!isBookmark) {
        this.$store.dispatch('event/createEventBookmark', this.event)
      } else {
        this.$store.dispatch('event/deleteEventBookmark', this.event)
      }
    },
    goToEventLink (url) {
      window.open(url, '__blank')
    }
  }
}
</script>