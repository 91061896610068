<template>
  <div>
    <div class="profile-header mb-4">
      <div class="relative">
        <div class="cover-container rounded-t-lg">
          <img
            v-if="!coverPhotoURL"
            src="@/assets/images/profile/cover-photos/cp-cover2.png"
            alt="user-profile-cover"
            class="responsive block"
          />
          <img
            v-else
            :src="coverPhotoURL"
            style="max-height: 376px"
            class="responsive block object-cover"
          />
        </div>
        <div class="partner-profile-img-container pointer-events-none">
          <div class="ml-auto pointer-events-auto flex" v-if="isEdit">
            <vs-button
              @click="toggleCoverImgUpdate"
              icon-pack="feather"
              radius
              icon="icon-camera"
              class="ml-2 lg:ml-4 update-cover-image-btn"
              ></vs-button
            >
          </div>
        </div>
      </div>
      <div class="lg:flex items-start p-5 bg-white pp-profile">
        <div class="relative p-pi">
          <vs-avatar :src="logoImg" size="90px" class="user-profile-img mr-3" />
          <div v-if="isEdit" class="pp-edit-icon">
            <a @click="toggleProfileImageUpdate" class="cursor-pointer"
              ><span class="material-icons">edit</span></a
            >
          </div>
        </div>
        <div class="lg:ml-4 w-full">
          <div class="">
            <div class="flex items-center">
              <div>
                <h4 class="font-semibold mb-1 mr-4">{{ organizationName }}</h4>
                <template v-if="!isEdit">
                  <vs-button
                    v-if="!isFollowed"
                    size="small"
                    @click="followPartner"
                    >Follow</vs-button
                  >
                  <vs-button v-else size="small" @click="unfollowPartner"
                    >Unfollow</vs-button
                  >
                </template>
              </div>
              <!-- Educator profile edit popup  -->
              <vs-button v-if="isEdit" icon-pack="feather"  icon="icon-edit" size="small"
              @click="toggleEducatorEditPopup"></vs-button>

              <educator-profile-edit-popup ref="educatorProfileEditRef" @educatorUpdated="getPartnerData"/>
            </div>

            <p class="text-muted mb-1 font-semibold">
              {{ partnerData.partnerType | partnerTypeFormatter }}
              <feather-icon v-if="userData.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-primary rounded-full text-white"></feather-icon>          
            </p>

            <!-- <div class="ml-auto">
          <vs-button size="small">Follow</vs-button>
        </div> -->
          </div>

          <partner-header-text-content
            ref="headerTextRef"
            :partnerData="partnerData"
          />
        </div>
      </div>
    </div>
    <CoverImgUpdatePopup
      ref="coverImgPopup"
      @onFinishUpload="setCoverPhotoURL"
    />
    <ProfileImgUpdatePopup
      ref="profileImgPopup"
      @onFinishUpload="setProfilePhotoURL"
    />
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import {
  partnerByUser,
  listUsers,
  getFollow,
  educatorByUser,
  getUser
} from '@/graphql/queries'
import CoverImgUpdatePopup from '../CoverImgUpdatePopup'
import ProfileImgUpdatePopup from '../ProfileImgUpdatePopup'
import PartnerHeaderTextContent from './PartnerHeaderTextContent'
import EducatorProfileEditPopup from './EducatorProfileEditPopup'
import vSelect from 'vue-select'
export default {
  name: 'PartnerProfileHeader',
  components: {
    CoverImgUpdatePopup,
    ProfileImgUpdatePopup,
    PartnerHeaderTextContent,
    EducatorProfileEditPopup,
    vSelect
  },
  data () {
    return {
      coverProfile: false,
      organizationName: '',
      logoImg: '',
      partnerData: '',
      userData: '',
      isFollowed: '',
      coverPhotoURL: ''
    }
  },
  computed: {
    isEdit () {
      return (
        this.$store.state.auth.userData.name === this.$route.params.userName
      )
    },
    partnerType () {
      return this.partnerData.partnerType
    }
  },
  methods: {
    toggleEducatorEditPopup () {
      this.$refs.educatorProfileEditRef.partnerId = this.partnerData.id
      this.$refs.educatorProfileEditRef.educatorId = this.partnerData.educatorData.id      
      this.$refs.educatorProfileEditRef.about = this.$refs.headerTextRef.about
      this.$refs.educatorProfileEditRef.profession = this.partnerData.educatorData.profession      
      this.$refs.educatorProfileEditRef.skills = this.partnerData.educatorData.skills
      this.$refs.educatorProfileEditRef.servicesOffered = this.partnerData.educatorData.servicesOffered
      this.$refs.educatorProfileEditRef.initSubjects = true
      this.$refs.educatorProfileEditRef.subjects = this.partnerData.educatorData.subjects
      this.$refs.educatorProfileEditRef.categoryText = this.partnerData.educatorData.category
      this.$refs.educatorProfileEditRef.selected_languages = this.partnerData.educatorData.languages.split(',')
      this.$refs.educatorProfileEditRef.location = this.partnerData.location
      this.$refs.educatorProfileEditRef.$refs.locSelRef.locationInput = this.partnerData.location
      this.$refs.educatorProfileEditRef.$refs.locSelRef.locationSelect = this.partnerData.location
      this.$refs.educatorProfileEditRef.$refs.locSelRef.selected = true
      this.$refs.educatorProfileEditRef.toggleEditPopup = true
    },
    toggleProfileImageUpdate () {
      this.$refs.profileImgPopup.active = true
    },
    toggleCoverImgUpdate () {
      this.$refs.coverImgPopup.coverProfile = true
    },
    async getPartnerData () {
      const partnerDataRes = await API.graphql(
        graphqlOperation(partnerByUser, {
          user: this.$route.params.userName
        })
      )
      if (partnerDataRes.data.partnerByUser.items.length !== 0) {
        this.partnerData = partnerDataRes.data.partnerByUser.items[0]
      }
      const listUsersRes = await API.graphql(
        graphqlOperation(listUsers, {
          name: this.$route.params.userName,
          filter: {
            isPartner: {
              eq: true
            }
          }
        })
      )
      if (listUsersRes.data.listUsers.items.length !== 0) {
        this.organizationName =
          listUsersRes.data.listUsers.items[0].displayName
        this.logoImg = listUsersRes.data.listUsers.items[0].photoURL
        this.coverPhotoURL = listUsersRes.data.listUsers.items[0].coverPhotoURL
        this.partnerData.location = listUsersRes.data.listUsers.items[0].location
      }
      if (this.partnerData.partnerType === 'EDUCATOR') {
        await this.fetchEducatorData()
      }
       const getUserRes = await API.graphql(graphqlOperation(getUser, { 
        name: this.$route.params.userName
      }))
      this.userData = getUserRes.data.getUser;
      this.$refs.headerTextRef.partnerData = this.partnerData
      this.$refs.headerTextRef.about = listUsersRes.data.listUsers.items[0].about
    },
    followPartner () {
      this.isFollowed = true
      this.$store.dispatch('follow/followPartner', this.$route.params.userName)
    },
    unfollowPartner () {
      this.isFollowed = false
      this.$store.dispatch(
        'follow/unfollowCurrentAuthor',
        this.$route.params.userName
      )
    },
    async checkIsFollowed () {
      const res = await API.graphql(
        graphqlOperation(getFollow, {
          user: this.$route.params.userName,
          follower: this.$store.state.auth.userData.name
        })
      )
      if (res.data.getFollow) {
        this.isFollowed =
          res.data.getFollow !== null &&
          Object.keys(res.data.getFollow).length !== 0
      } else {
        this.isFollowed = false
      }
    },
    setCoverPhotoURL (url) {
      this.coverPhotoURL = url
    },
    setProfilePhotoURL (url) {
      this.logoImg = url
    },
    goToPartnerWebsite (url) {
      if (!url.includes('https://')) {
        url = `https://${  url}`
      }
      window.open(url, '_blank')
    },
    async fetchEducatorData () {
      try {
        const res = await API.graphql(
          graphqlOperation(educatorByUser, {
            user: this.$route.params.userName
          })
        )
        if (res.data.educatorByUser.items[0]) {
          this.partnerData.educatorData = res.data.educatorByUser.items[0]
          let skills = [];
          for (let index = 0; index < this.partnerData.educatorData.skills.length; index++) {
            const element = this.partnerData.educatorData.skills[index];
            if(skills.indexOf(element) == -1){
              skills.push(element);
            }            
          }          
          this.partnerData.educatorData.skills = skills;
          let servicesOffered = [];
          for (let index = 0; index < this.partnerData.educatorData.servicesOffered.length; index++) {
            const element = this.partnerData.educatorData.servicesOffered[index];
            if(servicesOffered.indexOf(element) == -1){
              servicesOffered.push(element);
            }            
          }
          this.partnerData.educatorData.servicesOffered = servicesOffered;
          let subjects = [];
          for (let index = 0; index < this.partnerData.educatorData.subjects.length; index++) {
            const element = this.partnerData.educatorData.subjects[index];
            if(subjects.indexOf(element) == -1){
              subjects.push(element);
            }            
          }
          this.partnerData.educatorData.subjects = subjects;
          // this.partnerData.educatorData.services.educatorRef.languages = languages;
          // let language = [];
          // for (let index = 0; index < this.partnerData.educatorData.services.educatorRef.languages.length; index++) {
          //   const element = this.partnerData.educatorData.services.educatorRef.languages[index];
          //   if(languages.indexOf(element) == -1){
          //     language.push(element);
          //   }            
          // }
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleExpertiseOptionCreate () {
    }
  },
  created () {
    this.getPartnerData()
    this.checkIsFollowed()
   
  }
}
</script>

<style lang="scss" scoped>
.partner-profile-img-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -1.6rem;
  left: 10%;
  width: 80%;
  justify-content: space-between;
}
.cover-container > * {
  background-image: top center !important;
}
.text-truncate-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pp-profile .con-vs-avatar {
  background: transparent;
}
.p-pi:hover .pp-edit-icon {
  background-color: #dc123e;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50% !important;
  color: #fff !important;
  position: absolute;
  top: 5px;
  left: 70px;
  // display: inline-block;
  text-align: center;
  // -webkit-transition: all .1s ease-in-out;
  // -o-transition: all .1s ease-in-out;
  // transition: all .1s ease-in-out;
  // overflow: hidden;
}
.pp-edit-icon > a {
  color: #fff !important;
}
.pp-edit-icon > a > span {
  position: relative;
  font-size: 18px;
  color: #fff !important;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
</style>