<template>
  <div class="block">
    <div v-if="partnerData.partnerType === 'EDUCATOR'">
      <div class="lg:flex">
        <div class="w-full">
    <div class="" style=""><span class="font-semibold mr-2">Location:</span>{{ partnerData.location}}</div>
      <div class="" style=""><span class="font-semibold mr-2">Account Type:</span>{{ partnerData.educatorData.profession }}</div>
      <div v-if=" partnerData.educatorData.skills.length != 0" >
        <div  v-if=" partnerData.educatorData.skills[0] != null" class="mt-1">
          <span  class="font-semibold mr-2">Expertises:</span>{{ partnerData.educatorData.skills | formatEducatorArrayString }}
        </div>
      </div>    
      <div class="mt-1"><span class="font-semibold mr-2">Languages I speak:</span>{{ partnerData.educatorData.languages.replace('[','').replace(']','')}}</div>
      <div class="mt-1"><span class="font-semibold mr-2">About Me:</span>{{ about }}</div>
      <div class="mt-2 ">
        <div class="mr-2">
          <p class="font-semibold">Services Offered:</p>
          <div class="flex flex-wrap mt-1">
          <div class="rounded-full bg-custom-light px-3 py-2 mr-3" v-for="(item, index) in partnerData.educatorData.servicesOffered" :key="index"><span class="" >{{ item }}</span> </div>
          </div>
        </div>
      </div>

      <div v-if="partnerData.educatorData.subjects.length != 0" class="mt-1"> 
        <div v-if=" partnerData.educatorData.subjects[0] != null"  class="mr-2">
          <p class="font-semibold">Topics:</p>
          <div class="flex flex-wrap mt-1">
          <div class="rounded-full bg-custom-light px-3 py-2 mr-3" v-for="(item, index) in partnerData.educatorData.subjects" :key="index"><span class="" >{{ item }}</span> </div>
          </div>
          <!-- <div class="mt-1"><span class="font-semibold mr-2">Web:</span>{{ partnerData.website }}</div> -->
        </div>
      </div>
      <div class="mt-1">
        <!--
        <div class="mb-3"><span class="font-semibold mr-2">Web:</span>{{ partnerData.website }}</div>
         -->
      </div>
        </div>
        <div class="mt-3 md:mt-0 text-right">
          <a class="linkedin-icon cursor-pointer" @click="openLinkedIn()"><feather-icon icon="LinkedinIcon" svgClasses="h-5 w-5" class="" /></a>
      </div>
      </div>
      
      
    </div>
    <div v-else> 
      <p class="font-small-2" style="color:#609bfc;"><span class="font-semibold mr-2">Industry:</span>{{ partnerData.industry }}</p>
      <p class="text-muted mt-1"><span class="font-semibold mr-2">Location:</span>{{ partnerData.location }}</p>
      <p class="mt-2 partner-overview">{{ partnerData.overview }}</p>
      <p class="text-muted mt-2"><span class="font-semibold mr-2">Services offered:</span>{{ partnerData.specialities }}</p>
      <p class="mt-2">
        <a class="text-primary cursor-pointer" @click="goToPartnerWebsite(partnerData.website)">Visit our website</a>
      </p>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'PartnerHeaderTextContent',
  data () {
    return {
      partnerData: '',
      about: ''
    }
  },
  methods:{
    openLinkedIn(){
      if(this.partnerData.website==null||this.partnerData.website==''){
        window.open('https://www.linkedin.com/')
      }else{
         window.open(this.partnerData.website)
      }
     
    }
  }
}
</script>

<style lang="scss">
.bg-grey-light{
  // background: #f0f0f0 !important;
  // color: rgba(0, 0, 0, 0.7) !important;
  background: rgb(96, 155, 252) !important;
  color: #fff !important;
}
.bg-custom-light{
  margin-right: 5px !important;
  margin-bottom: 8px !important;
  font-size: 13px !important;
  line-height: 1.1 !important;
  padding: 4px 8px !important;
  // font-weight: 600 !important;
  color: rgb(53, 64, 83) !important;
  background-color: rgb(228, 234, 240) !important;
  border-radius: 4px !important;
  word-break: break-word;
}
.linkedin-icon{
  background-color: rgb(0, 123, 181);
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
}
.linkedin-icon .feather{
  color: #fff;
}
</style>