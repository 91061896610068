<template>
 <div>
    <vs-popup title="Confirm" :active.sync="popupActive">
      <div class="vx-row mb-2">
        <div class="vx-col w-full mb-3 mt-3">
          <span>Are you sure you want to delete this course?</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button size="small" @click="deleteItem()" class="mr-3 mb-2"
            >Delete</vs-button
          >
          <vs-button
            size="small"
            type="border"
            color="warning"
            @click="popupActive = false"
            class="mr-3 mb-2"
            >Cancel</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'CourseDeletePopup',
  data () {
    return {
      popupActive: false,
      id: '',
      index: ''
    }
  },
  methods: {
    deleteItem () {
      this.$store.dispatch('course/deleteCourse', {
        id: this.id,
        index: this.index
      }).then(() => {
        this.popupActive = false
      })
    }
  }

}
</script>