<template>
  <vs-popup title="Add your event" :active.sync="popupActive">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Title</label>
          <vs-input class="w-full" name="title" v-model="title" />
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Category</label>
          <!-- <vs-input class="w-full" name="category" v-model="category" /> -->
          <vs-select class="w-full" name="category" v-model="category">
            <vs-select-item :key="index" :value="category" :text="category" v-for="(category, index) in categories" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <label class="vs-input--label">Description</label>
          <vs-textarea class="w-full" name="description" v-model="description" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Presenter</label>
          <vs-input class="w-full" name="presenter" v-model="presenter" />
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Event Link</label>
          <vs-input class="w-full" name="url" v-model="url" />
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Start Date</label>
          <flat-pickr v-model="startDate" class="w-full" :config="datePickerConfig"/>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">End Date</label>
          <flat-pickr v-model="endDate" class="w-full" :config="datePickerConfig"/>
        </div>
      </div>
      <div class="vx-row mt-4">
         <div class="vx-col w-1/3">
          <label class="vs-input--label">Price</label>
          <vs-input class="w-full" name="price" v-model="price" />
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Discount Price</label>
          <vs-input class="w-full" name="discountPrice" v-model="discountPrice" />
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Method</label>
          <!-- <vs-input class="w-full" name="method" v-model="method" /> -->
          <vs-select class="w-full" name="method" v-model="method">
            <vs-select-item value="Online" text="Online" />
            <vs-select-item value="Face to face" text="Face to face" />
          </vs-select>
        </div>
      </div>

      <div class="vx-row mt-4" v-if="method === 'Face to face'">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">City</label>
          <!-- <vs-input class="w-full" name="city" v-model="city" /> -->
          <vs-select class="w-full" name="city" v-model="city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in locations"/>
          </vs-select>
        </div>
        <div class="vx-col w-1/2">
          <location-select ref="locSelRef" @onLocSelect="handleLocSelect" title="Address" />
        </div>
      </div>
      <div class="vx-row mt-4" v-if="method === 'Online'">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">City</label>
          <!-- <vs-input class="w-full" name="city" v-model="city" /> -->
          <vs-select class="w-full" name="city" v-model="city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in locations"/>
          </vs-select>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Zoom link</label>
          <vs-input class="w-full" name="zoomLink" v-model="zoomLink" />
        </div>
      </div>
      
      <div class="vx-row mt-4 flex items-start">
        <div class="vx-col w-1/2">
          <p v-if="!this.previewImg" class="font-semibold">Event Thumbnail</p> 
          <img v-else :src="this.previewImg" class="preview-img"/>
          <vs-button class="mt-2 sm:mb-0" @click="uploadImage">Upload Event Thumbnail</vs-button>
          <input ref="imageInputProfile" type="file" accept="image/*" @change="onImageChange"/>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Status</label>
          <vs-select v-model="status" class="w-full">
            <vs-select-item text="Open" :value="true" />
            <vs-select-item text="Closed" :value="false" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <label class="vs-input--label">Video (optional)</label>
          <vs-input class="w-full" name="city" v-model="videoUrl" />
        </div>
      </div>
      <div class="vx-ro mt-4">
        <div class="vx-col w-full">
        <vs-button class="float-right" @click="submit">Submit</vs-button>
        </div>
      </div>
    </vs-popup>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import LocationSelect from '@/views/profile/LocationSelect'
import { createErrorObject } from '@/utils'

export default {
  name: 'EventEditPopup',
  components: {
    flatPickr,
    LocationSelect
  },
  props: ['categories', 'locations'],
  data () {
    return {
      isAdd: true,
      popupActive: false,
      title: '',
      category: '',
      startDate: '',
      endDate: '',
      zoomLink: '',
      city: '',
      address: '',
      presenter: '',
      description: '',
      price: '',
      discountPrice: '',
      method: '',
      url: '',
      image: '',
      videoUrl: '',
      status: true,
      createdAt: '',
      id: '',
      index: '',
      previewImg: '',
      datePickerConfig: {
        minDate: "today",
        dateFormat: "m-d-Y h-i K",
        enableTime: true
      }
    }
  },
  methods: {
    uploadImage () {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.image = e.target.files[0]
      this.previewImg = URL.createObjectURL(this.image)
    },
    add () {
      this.isAdd = true
      this.popupActive = true
      this.title = '',
      this.category = ''
      this.startDate = ''
      this.endDate = ''
      this.zoomLink = ''
      this.city = ''
      this.address = ''
      this.presenter =  ''
      this.description = ''
      this.price = ''
      this.discountPrice = ''
      this.method = ''
      this.url = ''
      this.image = ''
      this.videoUrl = ''
      this.status = true
      this.createdAt = ''    
    },
    edit (event, index) {
      this.isAdd = false
      this.popupActive = true
      this.id = event.id
      this.index = index
      this.title = event.title
      this.category = event.category
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.zoomLink = event.zoomLink
      this.city = event.city
      this.address = event.address
      this.presenter = event.presenter
      this.description = event.description
      this.price = event.price
      this.discountPrice = event.discountPrice
      this.method = event.method
      this.url = event.url
      this.previewImg = event.imageUrl
      this.status = event.status
      if (event.address) {
        this.$refs.locSelRef.locationInput = event.address
        this.$refs.locSelRef.locationSelect = event.address
        this.$refs.locSelRef.selected = true
      }
    },
    handleLocSelect (address) {
      this.address = address
    },
    validateInput () {
      if (this.title === '') {
        this.$vs.notify(createErrorObject('Error', 'Title field is required'))
        return false
      }

      if (this.category === '') {
        this.$vs.notify(createErrorObject('Error', 'Category field is required'))
        return false
      }

      if (this.startDate === '') {
        this.$vs.notify(createErrorObject('Error', 'Start Date field is required'))
        return false
      }
      
      if (this.presenter === '') {
        this.$vs.notify(createErrorObject('Error', 'Presenter field is required'))
        return false
      }
      
      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Description field is required'))
        return false
      }
      return true
    },
    submit () {
      if (!this.validateInput()) return
      if (this.isAdd) {
        this.$store.dispatch('event/addEvent', {
          title: this.title,
          category: this.category,
          startDate: this.startDate,
          endDate: this.endDate,
          zoomLink: this.zoomLink,
          city: this.city,
          address: this.address,
          presenter: this.presenter,
          description: this.description,
          price: this.price,
          discountPrice: this.discountPrice,
          method: this.method,
          url: this.url,
          image: this.image,
          videoUrl: this.videoUrl,
          status: this.status,
          createdAt: new Date()
        }).then(() => {
          this.popupActive = false
        })
      } else {
        this.$store.dispatch('event/updateEvent', {
          id: this.id,
          index: this.index,
          title: this.title,
          category: this.category,
          startDate: this.startDate,
          endDate: this.endDate,
          zoomLink: this.zoomLink,
          city: this.city,
          address: this.address,
          presenter: this.presenter,
          description: this.description,
          price: this.price,
          discountPrice: this.discountPrice,
          method: this.method,
          url: this.url,
          image: this.image,
          videoUrl: this.videoUrl,
          status: this.status,
          createdAt: new Date()
        }).then(() => {
          this.popupActive = false
        })
      }
    }
  }
}
</script>
<style lang="css">
input[type="file"] {
  display: none;
}

.preview-img {
  max-width: 100%;
  max-height: 150px;
}
</style>