<template>
<div class="pp-people px-1 pb-4">
  <div class="vx-row">
    <!-- <div class="vx-col w-full"> -->
      <!-- <div class="flex flex-wrap pp-people"> -->
      <div class="vx-col w-full text-center mt-5" v-if="follows.length === 0">You haven't had any followers yet</div>
      <div v-else class="vx-col w-full lg:w-1/4 mt-5" v-for="(user, index) in follows"
        :key="index">
      <vs-card class="user-hover-card cursor-pointer mb-0">
        <div class="flex items-center" @click="goToProfile(user)">
          <div class="">
          <vs-avatar class="" size="large" :src="user.photoURL" />
          </div>
          <div class="ml-2">
            <p><a class="text-xl font-semibold" target="_blank" rel="nofollow">{{ user.displayName }}</a></p>
            <span class="text-md" v-if="!user.isPartner">{{ user.studyStatus }} at {{ user.universityName }}</span>
            <span class="text-md" v-else>{{ user.partnerData.partnerType | partnerTypeFormatter }} at {{ user.displayName }}</span>
          </div>
        </div>
      </vs-card>
      </div>
      <!-- </div> -->
    <!-- </div> -->
  </div>
</div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { getFollowers, partnerByUser } from '@/graphql/queries'
export default {
  name: 'PartnerFollows',
  data () {
    return {
      follows: []
    }
  },
  methods: {
    goToProfile (user) {
      if (user.isPartner) {
        this.$router.push(`/partner/${user.name}`)
      } else {
        this.$router.push(`/profile/${user.name}`)
      }
    }
  },
  created () {
    API.graphql(graphqlOperation(getFollowers, {
      user: this.$route.params.userName
    }))
      .then(res => {
        res.data.getFollowers.items.map(follows => {
          if (follows.followerInstance && follows.followerInstance.isPartner) {
            API.graphql(graphqlOperation(partnerByUser, {
              user: follows.follower
            })).then(partnerRes => {
              if(follows.followerInstance !== null){
                follows.followerInstance.partnerData = partnerRes.data.partnerByUser.items[0]
                this.follows.push(follows.followerInstance)
              }
            })
          } else {
             if(follows.followerInstance !== null){
              this.follows.push(follows.followerInstance)
             }
          }
         
        })
      })
  }
}
</script>

<style>
.user-hover-card {
  transition: top 4.0s ease-in, box-shadow 1.0s linear !important;
  position: relative;
}
.user-hover-card:hover {
  top: 2px;
  box-shadow: 0px;
}
.pp-people .vs-card--content{
  margin-bottom: 0;
}
.pp-people .vs-avatar--con-img img{
  object-fit: cover;
}
.pp-people .con-vs-card{
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}
</style>