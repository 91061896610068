<template>
  <div class="vx-col w-full md:w-full lg:w-full xl:w-1/2 mx-auto partner-educator-posts">
    <!-- <Loading v-if="isLoading"/> -->
    <template v-if="posts && posts.length !== 0">
      <Post  v-for="(feed, feedIndex) in posts" :key="feedIndex"
        class="cursor-pointer"
        @onClick="viewCurrentPost(feed)"
        :actor="''"
        :action="''"
        :feed="feed" :feedIndex="feedIndex" 
        :displayComment="false"
        :displayShare="false"
        :displayLikeAndCommentCount="true"
        :displayPostOption="true" />
    </template>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex justify-center mt-base">
          <div
            id="button-load-more-posts"
            class="vs-con-loading__container"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { partnerByUser } from '@/graphql/queries'
import { postsByAuthor } from '@/graphql/postsByAuthor'

import { getSharePost } from '@/graphql/getSharePost'
import { getPost } from '@/graphql/getPost'
import { getCompressImageUrl } from '@/utils'
import Post from '@/layouts/components/coursepal/Post/Post'
import Loading from '@/layouts/components/coursepal/Loading'
export default {
  name: 'PartnerPostsList',
  components: {
    Post,
    Loading
  },
  data () {
    return {
      limit : 10,
      nextToken : null,
      posts: []
    }
  },
  methods: {
     handleScroll () {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
        this.getPost();
      }
    },
    
    async getPost () {
      try {        
         
        this.$vs.loading({
          container: "#button-load-more-posts",
        });
        const res = await API.graphql(graphqlOperation(postsByAuthor, {
          author:this.$store.state.auth.userData.name,
          filter: {
            isDeleted: {
              eq: false
            }
          },
          sortDirection: 'DESC',
          limit : this.limit,
          nextToken : this.nextToken
        }))
        this.nextToken = res.data.postsByAuthor.nextToken;
        for (const feed of res.data.postsByAuthor.items) {
          feed.mediaFiles = []
          if (feed.medias && feed.medias.length > 0) {
            const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
            feed.mediaFiles = mediaFiles
          }
          const postRes = await API.graphql(graphqlOperation(getPost, {
              id: feed.id
          }))
          feed.ogMetaData = postRes.data.getPost.ogMetaData  
          if (feed.authorRef.isPartner) {
            const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
              user: feed.author
            }))
            feed.partnerData = partnerByUserRes.data.partnerByUser.items[0]
          }
          
          if (feed.sharePostID) {
            const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
              id: feed.sharePostID
            }))
            if (sharePostRes.data.getPost && sharePostRes.data.getPost.medias.length > 0) {
              const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
              sharePostRes.data.getPost.mediaFiles = mediaFiles
            }
            feed.sharePost = sharePostRes.data.getPost
          }
  
        }
        // this.posts = res.data.postsByAuthor.items        
        this.posts.push(...res.data.postsByAuthor.items);
        // for (let i = 0; i < res.data.postsByAuthor.items.length; i++) {
        //   const e1 = res.data.postsByAuthor.items[i];
        //   let alreadyPushed = false;
        //   for (let j = 0; j < this.posts.length; j++) {
        //     const e2 = this.posts[j];
        //     console.log(e1.id,e2.id);
        //     if(e1.id === e2.id){
        //       alreadyPushed = true;
        //     }         
        //   }
        //   if(alreadyPushed === false){
        //     this.posts.push(e1);
        //   }      
        // }
        this.$vs.loading.close('#button-load-more-posts > .con-vs-loading');
      } catch (err) {
        console.log(err)
      }
    },
    viewCurrentPost (post) {
      this.$router.push(`/post?postId=${post.id}`)
    }
  },
  // data () {
  //   return {
  //     posts: []
  //   }
  // },
  created () {
    window.addEventListener("scroll", this.handleScroll); 
    this.getPost()
    
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style lang="scss">

.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  overflow: unset !important;
}

#button-load-more-posts {
  width: 200px;
  height: 80px;
  justify-content: center;
  align-items: center;
}

</style>