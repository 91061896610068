<template>
<div>
  <div class="vx-col">
  <div class="flex items-center mt-base">
   
  </div>

    <div class="mt-base partner-jobs-lists">
      <div
        class="jp-job-description mb-5 mr-1"
        v-for="(job, index) in jobs"
        :key="index"
      >
        <vx-card>
          <div class="flex items-center mt-base">
          <div class="">
          <h5 class="flex mb-0">
            <feather-icon
              class="mr-2 text-primary"
              icon="BriefcaseIcon"
              svgClasses="w-5 h-5"
            /><span class="text-primary">{{job.title | title }}</span>
          </h5>
          </div>
          </div>

          <span class="small text-grey">Posted {{ job.createdAt | elapsedTime }}</span>
          <div class="mt-2">
            <span class="flex"
              ><feather-icon
                icon="BriefcaseIcon"
                class="mr-2"
                svgClasses="h-4 w-4"
              /><span><strong>Type: </strong> {{ job.ype }}</span></span
            >
            <span class="flex"
              ><feather-icon
                icon="MapPinIcon"
                class="mr-2"
                svgClasses="h-4 w-4"
              /><span><strong>Location: </strong>{{ job.location }}</span></span
            >
          </div>

          <div class="mt-2">
            <div class="mb-2 text-truncate" v-html="job.description"></div>
          </div>
          <div class="mt-2">
            <vs-button size="small" color="primary" type="filled" :href="job.url" target="_blank"
                >Read More</vs-button>
          </div>
        </vx-card>
      </div>
    </div>
  </div>  
</div>
</template>


<script>
export default {
  name: 'PartnerJobsList',
  created () {
    this.$store.dispatch('job/fetchJobsAndBookmarkJobs', this.$route.params.userName)
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.userName
    },
    jobs () {
      return this.$store.state.job.jobs
    }   
  }
}
</script>

<style lang="scss" scoped>
</style>