<template>
  <div class="mt-6">
    <vs-checkbox v-model="isSelected" class="w-full m-0">
      <p class="mx-2">{{ day }}</p>
    </vs-checkbox>
    <div class="my-2 flex items-center justify-between" :class="!isSelected ? 'hidden' : ''">
      <div class="pr-2 w-1/2">
      <!-- <vs-input class="w-full" placeholder="From" v-model="from" /> -->
      <vs-select class="w-full" v-model="from" @change="handleFromChange">
        <vs-select-item v-for="(item, index) in hourlist" :key="index" :text="item" :value="item" />
      </vs-select>
      </div>
      <div class="pl-2 w-1/2">
      <!-- <vs-input class="w-full" placeholder="To" v-model="to" /> -->
      <vs-select v-model="to" class="w-full">
        <vs-select-item v-for="(item, index) in hourlist.slice(fromIndex + 1, hourlist.length)" :key="index" :text="item" :value="item" />
      </vs-select>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { createErrorObject } from '@/utils'

export default {
  name: 'DayCheckbox',
  props: ['day', 'value'],
  data () {
    return {
      isSelected: false,
      from: '',
      fromIndex: 0,
      to: '',
      hourlist: [
        '09:00am',
        '10:00am',
        '11:00am',
        '12:00am',
        '01:00pm',
        '02:00pm',
        '03:00pm',
        '04:00pm',
        '05:00pm',
        '06:00pm',
        '07:00pm',
        '08:00pm',
        '09:00pm',
      ]
    }
  },
  methods: {
    handleFromChange (e) {
      this.fromIndex = this.hourlist.indexOf(e)
      // this.to = ''
    },
    validateInput () {
      if (this.from === '') {
        this.$vs.notify(createErrorObject('Error', 'From field cannot be empty'))
        return false
      }

      if (this.to === '') {
        this.$vs.notify(createErrorObject('Error', 'To field cannot be empty'))
        return false
      }

      const fromVal = moment(this.from, 'HH:mma')
      const toVal = moment(this.to, 'HH:mma')

      if (moment.duration(toVal.diff(fromVal)).asMinutes() < 0) {
        this.$vs.notify(createErrorObject('Session timeslot invalid', `${this.day}: To field is earlier than From field`))
        return false
      }
      return true
    }
  }
}
</script>