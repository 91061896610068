<template>
  <div>
    <vs-popup title="Edit Profile" :active.sync="toggleEditPopup">
      <div class="px-3">
      <vs-select
        v-model="profession"
        class="w-full select-large"
        label="Account Type"
      >
        <vs-select-item
          :key="index"
          :value="item"
          :text="item"
          v-for="(item, index) in professionOptions"
          class="w-full"
        />
      </vs-select>
      <div class="mt-5">
        <label class="">Expertises</label>
        <v-select
          v-model="skills"
          multiple
          taggable
          :closeOnSelect="false"
          :options="suggestSkillList"
          @option:created="handleExpertiseOptionCreate"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <vs-textarea
        class="w-full mt-5"
        placeholder="About Me"
        v-model="about"
      ></vs-textarea>

      <div class="mt-5">
        <label class="ml-1">Services offered</label>
        <v-select
          v-model="servicesOffered"
          multiple
          :closeOnSelect="false"
          :options="servicesOfferedList"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="mt-5">
        <label class="ml-1">
          Study Area/Category
        </label>
        <v-select
          v-model="category"
          label="text"
          :options="categories"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          @input="fetchTopic()"
        >
        </v-select>
      </div>

       <div class="mt-5">
          <!--z <vs-input class="w-full" v-model="subjects" label="Add topic you can teach:" @change=""/> -->
          <label class="ml-1">Topics</label>
            <!-- :disabled="category === ''" -->
          <v-select          
            v-model="subjects"
            label="text"
            multiple
            taggable
            :closeOnSelect="false"
            :options="subjectList"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            @option:created="handleSubjectOptionCreate"
          /> 
        </div>
        
           <div class="mt-5">
                  <label class="ml-1">Location</label>
                  <location-select ref="locSelRef" @onLocSelect="handleLocSelect" :isBootstrap="true" />
                </div>
               
         <div class="mt-5">
           <label class="ml-1">Languages</label>
                  <v-select v-model="selected_languages"
                    multiple
                    label="text" 
                    :closeOnSelect="false"
                    :options="langOptions" 
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
         </div>
        <div class="mt-4 mb-3">
        <vs-button class="" @click="submitUpdate">Submit</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api';
import { listExpertises } from '@/graphql/queries';
import { createExpertise, updateUser, updateEducator, updatePartner } from '@/graphql/mutations';
import { languages } from '@/langOptions';
import LocationSelect from '../../profile/LocationSelect.vue'
import vSelect from 'vue-select';
import axios from 'axios';
import { listCategorys, topicsByCategory} from '@/graphql/queries';
import { createCourseFeed, createTopic } from "@/graphql/mutations";
import { createErrorObject, createSuccessObject } from '@/utils'
export default {
  name: "EducatorProfileEditPopup",
  components: {
    vSelect,
    LocationSelect,
  },
  watch: {
    // category () {
    //   this.subjects = []
    //   this.fetchTopic()
    // },
    /*
    category (newVal) {
      // if (!this.catnoUpdate) {
        if (!this.initSubjects) {
          this.subjects = []
        }
        this.subjectList = []
        axios.post(`${process.env.VUE_APP_BASE_URL}/getAllMarketplaceSubjectsByStudyarea`, {
          study_area_id: newVal.val
        }).then(res => {
          for (const topic of res.data.data) {
            this.subjectList.push(topic.subject_name)
          }
        })
        this.initSubjects = false
      // }
    },
    */
   
    categoryText (newVal) {
      for (const cat of this.categories) {
        if (cat.text === newVal) {
          this.category = cat
          // this.fetchTopic();
          break
        }
      }
    }
  },
  data() {
    return {
      // catnoUpdate: false,
      partnerId:'',
      educatorId: '',
      toggleEditPopup: false,
      about: "",
      profession: "",
      selected_languages:[],
      professionOptions: [
         "Online Creator",
        "Institution",
        "Business",
        "Employer",
        "Clubs"
      ],
      skills: [],
      suggestSkillList: [],
      servicesOffered: [],
      servicesOfferedList: [
          'Higher Education',
        'Online Education',
        'Student Recruitment',
        "Career Guidance",
        "Employment Services"
      ],
      categoryText: '',
      category: '',
      categories: [],
      initSubjects: true,
      subjects: [],
      subjectList: [],
    };
  },
   computed: {
    langOptions () {
      return languages.map(lang => lang.name)
    }
  },
  methods: {
     async handleSubjectOptionCreate (newOption) {
      try {
        const res = await API.graphql(graphqlOperation(createTopic, {
          input: {
            category: this.category.val,
            name: newOption.text
          }
        }))
        this.subjectList.push({ text: newOption.text, val: res.data.createTopic.id })
      } catch (err) {
        console.log('handleSubjectOptionCreate', err)
      }
    },
    async fetchCategory () {
      try {
        const res = await API.graphql(graphqlOperation(listCategorys))
        this.categories = res.data.listCategorys.items.map(item => { return { text: item.name, val: item.id }})
        // console.log('categories', this.categories)
      } catch (err) {
        console.log('fetchCategory', err)
      } 
    },
     handleLocSelect (location) {
      this.location = location
    },
     async fetchTopic () {
      try {
        let category = ''
        this.subjects = []
        this.subjectList = []
        category = this.category.val
        const res = await API.graphql(graphqlOperation(topicsByCategory, {
          category
        }))
        this.subjectList = res.data.topicsByCategory.items.map(item => {
          return { text: item.name, val: item.id }
        })
        // for (const topic of res.data.topicsByCategory.items) {
          
        //     this.subjectList.push(topic.name)
        //   }
        
      } catch (err) {
        console.log('fetchTopic', err)
      }
    },
    handleExpertiseOptionCreate (newExpertise) {
      API.graphql(graphqlOperation(createExpertise, {
        input: {
          name: newExpertise
        }
      })).then(res => {
        this.suggestSkillList.push(res.data.createExpertise.name)
      }).catch(err => {
        console.log(err)
      })
    },
   
    validateInput () {
      if (this.profession === '') {
        this.$vs.notify(createErrorObject('Error', 'Profession field cannot be empty'))
        return false
      }
      if (this.skills.length === 0) {
        this.$vs.notify(createErrorObject('Error', 'Skills field cannot be empty'))
        return false
      }
      if (this.servicesOffered.length === 0) {
        this.$vs.notify(createErrorObject('Error', 'Services offered field cannot be empty'))
        return false
      }
      if (this.category === '') {
        this.$vs.notify(createErrorObject('Error', 'Category field cannot be empty'))
        return false
      }
      if (this.subjects.length === 0) {
        this.$vs.notify(createErrorObject('Error', 'Topics field cannot be empty'))
        return false
      }
      return true
    },
    async submitUpdate () {
      try {
        
        if (!this.validateInput()) return
        // console.log('userName', this.$route.params.userName)
        API.graphql(graphqlOperation(updateUser, {
          input: {
            name: this.$route.params.userName,
            about: this.about
          }
        })) 
        API.graphql(graphqlOperation(updatePartner, {
          input: {
            id: this.partnerId,
            mainOffice: this.location,
          }
        }))
        API.graphql(graphqlOperation(updateEducator, {
          input: {
            id: this.educatorId,
            skills: this.skills,
            profession: this.profession,
            servicesOffered: this.servicesOffered,
            category: this.category.text,
            subjects: this.subjects.map(subject => subject.text),
            languages: this.selected_languages.join(),
          }
        }))
        
        this.$vs.notify(createSuccessObject('Success', 'Educator Profile updated'))
        this.toggleEditPopup = false
        this.$emit('educatorUpdated')
        
      } catch (err) {
        this.$vs.notify(createErrorObject('Error', 'Unable to update Educator Profile'))
        console.log(err)
      }
    }
  },
  created() {
   // axios.post(`${process.env.VUE_APP_BASE_URL}/StudyAreaList`).then(res => {
   //   this.categories = res.data.data.map(sa => { return {text: sa.study_area, val: sa.stu_area_id}})
      
   // })
this.fetchCategory ()
    API.graphql(graphqlOperation(listExpertises)).then(res => {
      this.suggestSkillList = res.data.listExpertises.items.map(item => item.name)
      
    }).catch(err => console.log(err))
  },
};
</script>