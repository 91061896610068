<template>
  <div>
    <div v-if="courses.length !== 0">
    <course-list-item 
      v-for="(course, index) in courses" :course="course" :key="index"
      @toggleEditPopup="toggleEditPopup(course, index)"
      @toggleDeletePopup="toggleDeletePopup(course, index)" 
    />
    <course-edit-popup ref="courseEditPopup" :categories="categories" :locations="locations"/>
    <course-delete-popup ref="courseDeletePopup" />
    </div>
    <p v-else>Courses list is empty</p>
  </div>
</template>

<script>
import axios from 'axios'
import CourseListItem from '@/views/pages/course/CourseListItem.vue'
import CourseDeletePopup from '@/views/pages/course/CourseDeletePopup.vue'
import CourseEditPopup from '@/views/pages/course/CourseEditPopup.vue'
export default {
  name: 'PartnerCoursesList',
  components: {
    CourseListItem,
    CourseDeletePopup,
    CourseEditPopup
  },
  data () {
    return {
      categories: [],
      locations: []
    }
  },
  computed: {
    courses () {
      return this.$store.state.course.courseByOfferer
    }
  },
  mounted () {
    axios.post(`${process.env.VUE_APP_BASE_URL}/StudyAreaList`).then(res => {
      this.categories = res.data.data.map(sa => sa.study_area)
      this.categories.unshift('All Categories')
    })

    axios.post(`${process.env.VUE_APP_BASE_URL}/getAllLocations`).then(res => {
      this.locations = res.data.data.map(loc => loc.locationname)
      this.locations.unshift('All Locations')
    })
  },
  methods: {
    toggleEditPopup (course, index) {
      this.$refs.courseEditPopup.edit(course, index)
    },
    toggleDeletePopup (course, index) {
      this.$refs.courseDeletePopup.id = course.id
      this.$refs.courseDeletePopup.index = index
      this.$refs.courseDeletePopup.popupActive = true
    }
  }
}
</script>