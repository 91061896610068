<template>
  <div>
    <div v-if="events.length !== 0">
    <event-list-item 
      v-for="(event, index) in events" :key="index" :event="event" 
      @toggleEditPopup="toggleEditPopup(event, index)"
      @toggleDeletePopup="toggleDeletePopup(event, index)" />
    <event-edit-popup ref="eventEditPopup" :categories="categories" :locations="locations"/>
    <event-delete-popup ref="eventDeletePopup" />
    </div>
    <p v-else>Events list is empty</p>
  </div>
</template>

<script>
import axios from 'axios'
import EventListItem from '@/views/pages/event/EventListItem.vue'
import EventEditPopup from '@/views/pages/event/EventEditPopup.vue'
import EventDeletePopup from '@/views/pages/event/EventDeletePopup.vue'

export default {
  name: 'PartnerEventsList',
  components: {
    EventListItem,
    EventDeletePopup,
    EventEditPopup
  },
  data () {
    return {
      categories: [],
      locations: []
    }
  },
  computed: {
    events () {
      return this.$store.state.event.eventByOfferer
    }
  },
  mounted () {
    axios.post(`${process.env.VUE_APP_BASE_URL}/StudyAreaList`).then(res => {
      this.categories = res.data.data.map(sa => sa.study_area)
      this.categories.unshift('All Categories')
    })

    axios.post(`${process.env.VUE_APP_BASE_URL}/getAllLocations`).then(res => {
      this.locations = res.data.data.map(loc => loc.locationname)
      this.locations.unshift('All Locations')
    })
  },
  methods: {
     toggleEditPopup (event, index) {
      this.$refs.eventEditPopup.edit(event, index)
    },
    toggleDeletePopup (event, index) {
      this.$refs.eventDeletePopup.id = event.id
      this.$refs.eventDeletePopup.index = index
      this.$refs.eventDeletePopup.popupActive = true
    }
  }
}
</script>