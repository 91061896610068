<template>
  <div>
    <vs-popup title="Add your course" :active.sync="popupActive">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Title</label>
          <vs-input class="w-full" name="title" v-model="title" />  
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Category</label>
          <!-- <vs-input class="w-full" name="category" v-model="category" /> -->
          <vs-select class="w-full" name="category" v-model="category">
            <vs-select-item :key="index" :value="category" :text="category" v-for="(category, index) in categories" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <label class="vs-input--label">Description</label>
          <vs-textarea class="w-full" name="description" v-model="description" />
        </div>
      </div>
      <div class="vx-row"> 
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Educator</label>
          <vs-input class="w-full" name="educator" v-model="educator" />
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Course Link</label>
          <vs-input class="w-full" name="url" v-model="url" />
        </div>
      </div>
      <div class="vx-row mt-4">
         <div class="vx-col w-1/3">
          <label class="vs-input--label">Price</label>
          <vs-input class="w-full" name="price" v-model="price" />
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Discount Price</label>
          <vs-input class="w-full" name="discountPrice" v-model="discountPrice" />
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Method</label>
          <!-- <vs-input class="w-full" name="method" v-model="method" /> -->
          <vs-select class="w-full" name="method" v-model="method">
            <vs-select-item value="Online" text="Online" />
            <vs-select-item value="Face to face" text="Face to face" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">City</label>
          <!-- <vs-input class="w-full" name="city" v-model="city" /> -->
          <vs-select class="w-full" name="city" v-model="city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in locations"/>
          </vs-select>
        </div>
        <div class="vx-col w-1/2">
          <!-- <label class="vs-input--label">Address</label> -->
          <!-- <vs-input class="w-full" name="address" v-model="address" /> -->
          <location-select ref="locSelRef" @onLocSelect="handleLocSelect" title="Address"/>
        </div>
      </div>
      <div class="vx-row mt-4 flex items-start">
        <div class="vx-col w-1/2">
          <p v-if="!this.previewImg" class="font-semibold">Course Thumbnail</p> 
          <img :src="this.previewImg" class="preview-img"/>
          <vs-button class="mt-2 sm:mb-0 w-full" @click="uploadImage">Upload Course Thumbnail</vs-button>
          <input ref="imageInputProfile" type="file" accept="image/*" @change="onImageChange"/>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Status</label>
          <vs-select class="w-full" v-model="status">
            <vs-select-item text="Open" :value="true" />
            <vs-select-item text="Closed" :value="false" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <label class="vs-input--label">Video (optional)</label>
          <vs-input class="w-full" name="city" v-model="videoUrl" />
        </div>
      </div>
      <div class="vx-ro mt-4">
        <div class="vx-col w-full">
        <vs-button class="float-right" @click="submit">Submit</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import LocationSelect from '@/views/profile/LocationSelect'
import { createErrorObject } from '@/utils'

export default {
  name: 'CourseEditPopup',
  components: {
    LocationSelect
  },
  props: ['categories', 'locations'],
  data () {
    return {
      popupActive: false,
      title: '',
      category: '',
      description: '',
      price: '',
      discountPrice: '',
      method: '',
      city: '',
      address: '',
      image: '',
      videoUrl: '',
      url: '',
      status: true,
      previewImg: '',
      id: '',
      index: '',
      isAdd: false,
      educator: '',
    }
  },
  methods: {
    uploadImage() {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.image = e.target.files[0]
      this.previewImg = URL.createObjectURL(this.image)
    },
    add () {
      this.popupActive = true
      this.isAdd = true
      this.title = ''
      this.category = ''
      this.description = ''
      this.price = ''
      this.discountPrice = ''
      this.method = ''
      this.city = ''
      this.address = ''
      this.image = ''
      this.videoUrl = ''
      this.status = true
      this.url = ''
      this.educator = ''
    },
    edit (course, index) {
      this.popupActive = true
      this.isAdd = false
      this.title = course.title
      this.category = course.category
      this.description = course.description
      this.price = course.price
      this.discountPrice = course.discountPrice
      this.method = course.method
      this.city = course.city
      this.address = course.address
      this.image = course.image
      this.videoUrl = course.videoUrl
      this.status = course.status
      this.url = course.url
      this.index = index
      this.id = course.id
      this.educator = course.educator
      this.previewImg = course.imageUrl
      this.$refs.locSelRef.locationInput = course.address
      this.$refs.locSelRef.locationSelect = course.address
      this.$refs.locSelRef.selected = true
    },
    handleLocSelect (address) {
      this.address = address
    },
    validateInput () {
      if (this.title === '') {
        this.$vs.notify(createErrorObject('Error', 'Title field is required'))
        return false
      }

      if (this.category === '') {
        this.$vs.notify(createErrorObject('Error', 'Category field is required'))
        return false
      }

      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Description field is required'))
        return false
      }

      if (this.educator === '') {
        this.$vs.notify(createErrorObject('Error', 'Educator field is required'))
        return false
      }

      if (this.city === '') {
        this.$vs.notify(createErrorObject('Error', 'City field is required'))
        return false
      }

      if (this.method === '') {
        this.$vs.notify(createErrorObject('Error', 'Method field is required'))
        return false
      }
      return true

    },
    submit () {
      if (!this.validateInput()) return
      if (this.isAdd) {
        this.$store.dispatch('course/addCourse', {
          title: this.title,
          category: this.category,
          educator: this.educator,
          description: this.description,
          price: this.price === '' ? this.price = 0 : parseInt(this.price),
          discountPrice: this.discountPrice === '' ? this.discountPrice = 0 : parseInt(this.discountPrice),
          method: this.method,
          city: this.city,
          address: this.address,
          image: this.image,
          videoUrl: this.videoUrl,
          status: this.status,
          url: this.url,
        }).then(() => this.popupActive = false)
      } else {
        this.$store.dispatch('course/updateCourse', {
          id: this.id,
          index: this.index,
          title: this.title,
          category: this.category,
          educator: this.educator,
          description: this.description,
          price: this.price === '' ? this.price = 0 : parseInt(this.price),
          discountPrice: this.discountPrice === '' ? this.discountPrice = 0 : parseInt(this.discountPrice),
          method: this.method,
          city: this.city,
          address: this.address,
          image: this.image,
          videoUrl: this.videoUrl,
          status: this.status,
          url: this.url,
        }).then(() => this.popupActive = false)
      }
    }
  }
}
</script>

<style lang="css">
input[type="file"] {
  display: none;
}

.preview-img {
  max-width: 100%;
  max-height: 150px;
}
</style>