<template>
  <div id="profile-page">
    <div class="vx-row">
      <div class="vx-col w-full">
        <partner-profile-header />
      </div>
    </div>

    <vs-tabs alignment="center">
      <vs-tab label="Posts">
        <partner-posts-list />
      </vs-tab>
      <!-- <vs-tab label="Courses">
        <partner-courses-list />
      </vs-tab>  -->
      <vs-tab label="Groups">
        <partner-course-feeds />
      </vs-tab>
      <vs-tab label="Sessions">
        <educator-services />
      </vs-tab>  
     <!-- <vs-tab label="Jobs">
        <PartnerJobsList />
      </vs-tab> -->
      
      <!--  <vs-tab label="Events">
        <partner-events-list />
      </vs-tab> -->
      <vs-tab label="Followers">
        <partner-follows />
      </vs-tab>
    </vs-tabs>

  </div>

</template>

<script>
import ProfileHeader from '../ProfileHeader'
import PartnerProfileHeader from './PartnerProfileHeader'
import PartnerFollows from './PartnerFollows'
import PartnerPostsList from './PartnerPostsList'
import PartnerJobsList from './PartnerJobsList'
import PartnerCoursesList from './PartnerCoursesList.vue'
import PartnerEventsList from './PartnerEventsList.vue'
import EducatorServices from './EducatorServices/EducatorServices.vue'
import PartnerCourseFeeds from './PartnerCourseFeeds/PartnerCourseFeeds.vue'

export default {
  name: 'PartnerProfile',
  components: {
    ProfileHeader,
    PartnerProfileHeader,
    PartnerFollows,
    PartnerPostsList,
    PartnerJobsList,
    PartnerEventsList,
    PartnerCoursesList,
    EducatorServices,
    PartnerCourseFeeds
  },
  watch: {
    '$route' (val) {    
      this.fetchCoursesAndEvents(val)
    }
  },
  methods: {
    fetchCoursesAndEvents (name) {
      this.$vs.loading()
      const coursePromise = this.$store.dispatch('course/fetchCourseByOfferer', name)
      const eventPromise = this.$store.dispatch('event/fetchEventsByOfferer', name)
      Promise.all([coursePromise, eventPromise]).then(() => {
        this.$vs.loading.close()
      })
    }
  },
  mounted () {
  this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  created () {
    this.fetchCoursesAndEvents(this.$route.params.userName)
  }
}
</script>

<style scoped>

</style>
