<template>
  <vs-popup ref="popupRef" title="Confirm Delete" :active.sync="active">
    <div class="my-2">Are you sure you want to delete this service?</div>
    <vs-button @click="deleteService">Delete</vs-button>
  </vs-popup>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { deleteEducatorService } from '@/graphql/mutations'
import { createErrorObject, createSuccessObject } from '@/utils'
export default {
  name: 'EducatorServiceDeletePopup',
  data () {
    return {
      active: false,
      id: '',
      index: '',
    }
  },
  methods: {
    async deleteService () {
      try {
        this.$vs.loading()
        const deleteRes = await API.graphql(graphqlOperation(deleteEducatorService, {
          input: {
            id: this.id
          }
        }))

        this.$vs.notify(createSuccessObject('Success', 'Service deleted'))
        this.$emit('deleteFinished', this.index)
        this.active = false
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify(createErrorObject('Error', 'Cannot delete educator\s service'))
        console.log(err)
      }
    }
  }
}
</script>