<template>
<div>
  <div class="vx-card mt-base">
        <div class="vx-row item-details no-gutter">
          <div
            class="vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center product-image cursor-pointer"
          >
            <img class="p-4" :src="course.imageUrl" />
          </div>
          <div class="vx-col sm:w-1/2 flex items-center">
            <div class="p-5 pt-6 w-full">
              <div class="flex items-center justify-between">
              <h5 class="font-semibold mb-1 text-primary">{{ course.title }}</h5>
              <div>
                <template v-if="isEdit">
                  <feather-icon
                      class="cursor-pointer hover:bg-grey p-2 rounded"
                      @click="$emit('toggleEditPopup')"
                      icon="EditIcon"
                      :svgClasses="iconSize"
                    />
                  <feather-icon
                    class="cursor-pointer hover:bg-grey p-2 rounded"
                    @click="$emit('toggleDeletePopup')"
                    icon="Trash2Icon"
                    :svgClasses="iconSize"
                  />
                </template>
              </div>
              </div>
               <div class="mt-1">
              <p>
                <span class="font-semibold">Educator:</span
                ><span class="text-muted ml-2">{{ course.educator }}</span>
              </p>
               </div>
               <div class="mt-1">
              <p>
                <span class="font-semibold">Category:</span
                ><span class="text-muted ml-2">{{ course.category }}</span>
              </p>
               </div>
               <div class="mt-1">
              <p>
                <span class="font-semibold">Status:</span
                ><span class="text-muted ml-2">{{ course.status ? 'Open' : 'Closed' }}</span>
              </p>
               </div>
              <div class="mt-1">
                <p>
                  <span class="font-semibold">Method</span
                  ><span class="text-muted ml-2">{{ course.method }}</span>
                </p>
              </div>
              <div class="mt-1" v-if="course.method !== 'Online'">
                <p>
                  <span class="font-semibold">Location</span
                  ><span class="text-muted ml-2">{{ course.address }}</span>
                </p>
              </div>
              <!-- <div class="mt-3 text-truncate">
                <span class="font-semibold">Description:</span>
                <p v-html="course.description"></p>
              </div> -->
              
               <div class="mt-2">
                <p>
                  <span class="font-semibold">Offered by:</span
                  ><span class="text-muted ml-2">{{ course.offererRef.displayName }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/4 w-full flex border-left p-4">
            <div class="flex flex-col w-full">
              <feather-icon
                class="cursor-pointer hover:bg-grey p-2 rounded ml-auto"
                icon="BookmarkIcon"
                @click="toggleBookmark(course.isBookmark)"
                :svgClasses="course.isBookmark ? iconSize + ' text-warning fill-current stroke-current' : iconSize"
              />
              <div class="my-6">
                <h5 class="">
                  <span v-if="course.discountPrice" class="font-bold text-success"
                    >${{ course.discountPrice }}</span
                  >
                  <span v-else>This course is free</span>
                  <span v-if="course.discountPrice"
                    class="text-primary ml-2"
                    style="text-decoration: line-through"
                    >${{ course.price }}</span
                  >
                </h5>
                <p v-if="course.method !== 'Online' ">
                  <span class="font-semibold">City:</span
                  ><span class="text-muted ml-2">{{
                    course.city
                  }}</span>
                </p>
                <div class="mt-1">
                <p v-if="course.address">
                  <span class="font-semibold">Address</span
                  ><span class="text-muted ml-2">{{
                    course.address
                  }}</span>
                </p>
                </div>
                <p class="text-light-blue">
                  <vs-button
                    @click="popupActive = true"
                    class="mt-4 cursor-pointer"
                    >View course</vs-button>
                  <!-- <vs-button
                    @click="goToCoursePage(course.url)"
                    class="mt-4 cursor-pointer"
                    >Go to course</vs-button> -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- View course popup -->
      <vs-popup
      ref=""
      title="Course details"
      :active.sync="popupActive"
    >
      <div class="view-course px-4 py-2">
        <div class="">
        <h4 class="font-semibold mb-1 text-primary">{{ course.title }}</h4>
        </div>
        <div class="mt-3">
          <h5 class="">
                  <span v-if="course.discountPrice" class="font-bold text-success"
                    >${{ course.discountPrice }}</span
                  >
                  <span v-else>This course is free</span>
                  <span v-if="course.discountPrice"
                    class="text-primary ml-2"
                    style="text-decoration: line-through"
                    >${{ course.price }}</span
                  >
                </h5>
        </div>
           <div class="mt-1">
              <p>
                <span class="font-semibold">Educator:</span
                ><span class="text-muted ml-2">{{ course.educator }}</span>
              </p>
               </div>
               <div class="mt-1">
              <p>
                <span class="font-semibold">Category:</span
                ><span class="text-muted ml-2">{{ course.category }}</span>
              </p>
               </div>
               <div class="mt-1">
              <p>
                <span class="font-semibold">Status:</span
                ><span class="text-muted ml-2">{{ course.status ? 'Open' : 'Closed' }}</span>
              </p>
               </div>
              <div class="mt-1">
                <p>
                  <span class="font-semibold">Method:</span
                  ><span class="text-muted ml-2">{{ course.method }}</span>
                </p>
              </div>
              <div class="mt-1" v-if="course.method !== 'Online'">
                <p>
                  <span class="font-semibold">Location:</span
                  ><span class="text-muted ml-2">{{ course.address }}</span>
                </p>
              </div>
              <div class="mt-1">
                <p v-if="course.method !== 'Online' ">
                  <span class="font-semibold">City:</span
                  ><span class="text-muted ml-2">{{
                    course.city
                  }}</span>
                </p>
              </div>

              <div class="mt-3">
                <span class="font-semibold lead">Description:</span>
                <p v-html="course.description"></p>
              </div>
              <div class="mt-2">
                <p>
                  <span class="font-semibold">Offered by:</span
                  ><span class="text-muted ml-2">{{ course.offererRef.displayName }}</span>
                </p>
              </div>
              <div class="mt-3">
                <vs-button
                    @click="goToCoursePage(course.url)"
                    class="mt-4 cursor-pointer"
                    >Go to course</vs-button>
              </div>
      </div>
    </vs-popup>
</div>
</template>

<script>
export default {
  name: 'CourseListItem',
  props: ['course'],
  data () {
    return {
      popupActive: false,
      iconSize: 'w-5 h-5'
    }
  },
  computed: {
    isEdit () {
      return this.course.offerer === this.$store.state.auth.userData.name
    }
  },
  methods: {
    toggleBookmark (isBookmark) {
      if (!isBookmark) {
        this.$store.dispatch('course/createCourseBookmark', this.course)
      } else {
        this.$store.dispatch('course/deleteCourseBookmark', this.course)
      }
    },
    goToCoursePage (url) {
      window.open(url, '__blank')
    }
  }
}
</script>